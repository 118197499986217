<template>
  <div :key="refreshComponent">
    <div
      v-if="!surveySubmitted"
      class="flex flex-col gap-8 bg-silver px-16 py-10"
    >
      <img
        src="@/assets/logos/rushden_horizontal.png"
        class="mx-auto w-1/3"
        alt=""
      />
      <div :key="activeSurvey">
        <span class="font-medium">Upload Physical Survey</span>
        <div
          class="flex flex-col gap-10 bg-white my-8 py-12 px-12 shadow-lg rounded-lg border border-grey-200"
        >
          <div
            v-for="(item, questionIndex) in activeSurvey?.questions"
            :key="questionIndex"
            class="flex flex-col gap-8"
          >
            <span class="font-extralight"
              >Q{{ questionIndex + 1 }} - {{ item.question }}</span
            >
            <div
              class="grid grid-cols-5 gap-4 xs:grid-rows-5 xs:grid-cols-1 sm:grid-rows-5 sm:grid-cols-1 md:grid-cols-5 md:grid-rows-1 lg:grid-cols-5 lg:grid-rows-1"
            >
              <div v-for="(option, optionIndex) in options" :key="optionIndex">
                <input
                  type="radio"
                  :name="questionIndex"
                  :value="option"
                  :id="`${questionIndex}-${optionIndex}`"
                  class="peer hidden"
                  :checked="selectedOption[questionIndex] === option"
                  @change="
                    handleOptionChange(questionIndex, option, optionIndex)
                  "
                />
                <label
                  :for="`${questionIndex}-${optionIndex}`"
                  class="flex cursor-pointer text-left rounded-md border border-textGray-600 bg-white p-4 text-gray-900 hover:border-gray-200 checked:border-teal checked:bg-teal checked:text-white"
                >
                  <p
                    class="text-sm font-medium"
                    :class="{
                      'text-white':
                        selectedOption[questionIndex] === optionIndex + 1,
                    }"
                  >
                    {{ option }}
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-8">
            <h1 class="font-semibold text-xl">Your feedback here</h1>
            <div>
              <label
                class="block text-gray-500 text-xs font-bold mb-2"
                for="question"
              >
                What was the best highlight of your visit (If you happen to know
                who helped it make better)?
              </label>
              <input
                v-model="positiveFeedback"
                class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                id="question"
                type="text"
                maxlength="200"
                placeholder="Type here"
              />
              <p class="text-gray-600 text-xs">
                Type your feedback here between 1 to 200 characters
              </p>
            </div>
            <div>
              <label
                class="block text-gray-500 text-xs font-bold mb-2"
                for="question"
              >
                Is there anything we can do to improve?
              </label>
              <input
                v-model="negativeFeedback"
                class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                id="question"
                type="text"
                maxlength="200"
                placeholder="Type here"
              />
              <p class="text-gray-600 text-xs">
                Type your feedback here between 1 to 200 characters
              </p>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <button
            class="border border-teal hover:bg-teal hover:text-white h-12 w-34 font-light text-teal rounded-md"
            :class="{ 'opacity-50 cursor-not-allowed': disableSubmitted }"
            :disabled="disableSubmitted"
            @click="handleSubmit(true)"
          >
            Finish
          </button>
          <button
            class="bg-teal h-12 w-34 font-light text-white rounded-md"
            :class="{ 'opacity-50 cursor-not-allowed': disableSubmitted }"
            :disabled="disableSubmitted"
            @click="handleSubmit(false)"
          >
            Save & Next
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col gap-14 px-16 py-10 justify-content text-center"
    >
      <img
        src="@/assets/logos/rushden_horizontal.png"
        class="mx-auto w-1/3"
        alt=""
      />
      <img
        src="@/assets/logos/excellence.png"
        class="mx-auto"
        alt=""
        height="128"
        width="128"
      />
      <div class="text-teal text-4xl font-bold mt-6">CONGRATULATIONS</div>
      <div class="font-medium text-sm">{{ successMessage }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, reactive, inject, onMounted } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";

const store = useStore();
const toast = inject("toast");

const positiveFeedback = ref("");
const negativeFeedback = ref("");
const surveySubmitted = ref(false);
const localQuestions = ref([]);
const disableSubmitted = ref(true);
const refreshComponent = ref(false);
const successMessage = ref(
  "You have successfully submitted  survey. You may safely close the tab now."
);

onMounted(async () => {
  await store.dispatch("MAPatientSurveys/fetchActiveSurvey");
});

const activeSurvey = computed(() => {
  return store.getters["MAPatientSurveys/getActiveSurvey"];
});

const surveyEntry = reactive({
  practice: localStorage.getItem("practice"),
  survey: activeSurvey.value?.id,
  questions: [],
  feedbacks: [
    {
      type: "positive",
    },
    {
      type: "negative",
    },
  ],
});

const options = [
  "Strongly Disagree",
  "Disagree",
  "Neutral",
  "Agree",
  "Strongly Agree",
];

const selectedOption = ref(
  Array(activeSurvey.value?.questions?.length).fill(null)
);

const handleOptionChange = (questionIndex, option) => {
  const optionMap = {
    "Strongly Disagree": 1,
    Disagree: 2,
    Neutral: 3,
    Agree: 4,
    "Strongly Agree": 5,
  };
  disableSubmitted.value = false;
  selectedOption.value[questionIndex] = optionMap[option];
};

const handleSubmit = (isFinish) => {
  localQuestions.value = activeSurvey.value.questions;
  const mappedQuestions = localQuestions.value?.map((question, index) => ({
    survey_question: question.id,
    option: selectedOption.value[index],
  }));

  surveyEntry.questions = mappedQuestions;

  const mappedFeedbacks = surveyEntry.feedbacks?.map((feedback) => ({
    type: feedback.type,
    text:
      feedback.type == "positive"
        ? positiveFeedback.value
        : negativeFeedback.value,
  }));

  surveyEntry.feedbacks = mappedFeedbacks;
  for (let i = 0; i < surveyEntry.questions?.length; i++) {
    if (!surveyEntry.questions[i].option) {
      toast.show(`Please choose an option for Question no: ${i + 1}`);
      return;
    }
  }
  for (let i = 0; i < surveyEntry.feedbacks?.length; i++) {
    if (surveyEntry.feedbacks[i].text === "") {
      toast.show(`Please add your feedback(s) as well`);
      return;
    }
  }

  surveyEntry.survey = activeSurvey.value?.id;
  if (!isFinish) {
    store.dispatch("MAPatientSurveys/submitSurvey", surveyEntry).then(() => {
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Patient Survey Submitted Successfully",
        iconHtml: `<img src="${icon}" />`,
        width: "32em",
        customClass: {
          title: "popup-title",
          popup: "popup-border",
          content: "popup-text",
          icon: "popup-icon-border",
        },
        timer: 2000,
        showConfirmButton: false,
        background: `rgba(225, 249, 240, 1)`,
      });
      selectedOption.value.splice(0);
      disableSubmitted.value = true;
      negativeFeedback.value = "";
      positiveFeedback.value = "";
      refreshComponent.value = !refreshComponent.value;
    });
  } else {
    store.dispatch("MAPatientSurveys/submitSurvey", surveyEntry).then(() => {
      surveySubmitted.value = true;
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Patient Survey Submitted Successfully",
        iconHtml: `<img src="${icon}" />`,
        width: "32em",
        customClass: {
          title: "popup-title",
          popup: "popup-border",
          content: "popup-text",
          icon: "popup-icon-border",
        },
        timer: 2000,
        showConfirmButton: false,
        background: `rgba(225, 249, 240, 1)`,
      });
    });
  }
};
</script>
